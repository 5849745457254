import styled from "styled-components";

const StyledSubtittle = styled.div`
  margin: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: x-large;
  color: #f0f0f0;
  
  @media (max-width: 600px){
    font-size: medium;
  }
`;

export default StyledSubtittle;