import logo from './images/logo-strona-glowna.png'
import baner from "./images/baner1.jpg"
import './App.css';
import StyledParagraf from "./paragraf";
import StyledSubtittle from "./images/subtittle";

function App() {
  return (
      <>
        <div className={"logo-div"}>
            <div>
              <img alt={"4BELUA"} src={logo}  className={"logo"}/>
              <StyledParagraf>Tworzymy się dla was!</StyledParagraf>
              <StyledSubtittle>W razie pytań prosimy o kontakt: bok@4belua.pl</StyledSubtittle>
            </div>
        </div>
        <img alt={"Miłego Dnia!"} className={"baner"} src={baner}/>
      </>
  );
}

export default App;
