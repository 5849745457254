import styled from "styled-components";

const StyledParagraf = styled.p`
   display: flex;
  text-align: center;
  justify-content: center;
  font-size: xxx-large;
  color: #f0f0f0;
  
  @media (max-width: 600px){
    font-size: x-large;
  }
`;

export default StyledParagraf;
